import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class Address {
  constructor () {
    const address = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.address = address
  }

  async post (data) {
    await AuthToken.setAuth(this.address)

    return this.address({
      url: 'v1/party_address',
      method: 'POST',
      data: data
    })
  }

  async get (uuid) {
    await AuthToken.setAuth(this.address)

    return this.address({
      url: `v1/party_address/${uuid}`,
      method: 'GET'
    })
  }

  async getList (keyId, limit, offset, paging = true) {
    await AuthToken.setAuth(this.address)

    if (paging) {
      const url = `v1/party_address/${keyId}`
      return paginate(this.address, url, limit, offset)
    }
    return this.address({
      url: `v1/party_address/${keyId}/${limit}/${offset}`,
      method: 'GET'
    })
  }

  async put (uuid, data) {
    await AuthToken.setAuth(this.address)

    return this.address({
      url: `v1/party_address/${uuid}`,
      method: 'PUT',
      data: data
    })
  }
}

export default new Address()
