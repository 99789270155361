<template>
  <v-form ref="form" v-model="valid">
    <v-container class="ma-0 pa-0" fluid>
      <v-data-table
        id="addressList"
        :height="tableSize"
        :headers="headers"
        :search="search"
        :footer-props="{'items-per-page-options': pageSizes}"
        :items-per-page="pageSize"
        :items.sync="addressList"
        dense
        fixed-header
        :show-select="hasWriteAccess"
        single-select
        item-key="id"
        v-model="selected"
        @click:row="hasWriteAccess ? emitSelect : null"
        @item-selected="selectRow">

        <template v-slot:top>
          <v-toolbar flat style="cursor: default;">
            <v-toolbar-title class="ml-2 title">
              <v-icon left>mdi-map-marker-radius</v-icon> Address
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              v-if="hasWriteAccess"
              id="newAddressBtn"
              color="primary"
              dark
              absolute
              right
              @click="createNewAddress()">
              Add Address
            </v-btn>
          </v-toolbar>
          <v-divider/>
        </template>

        <template v-slot:[`item.created_on`]="{ item }">
          <span v-if="item.created_on">
            {{ moment(item.created_on).format($config.timestamp) }}
          </span>
        </template>
        <template v-slot:[`item.updated_on`]="{ item }">
          <span v-if="item.updated_on">
            {{ moment(item.updated_on).format($config.timestamp) }}
          </span>
        </template>
      </v-data-table>

      <v-dialog
        @click:outside="closeEdit"
        v-model="showDialog"
        v-if="showDialog">
        <v-card flat>
          <v-card-title>
            <v-icon left>
              mdi-pencil
            </v-icon> {{ crud | capitalize}} Address
            <v-btn
              id="closeEdit"
              icon
              absolute
              right
              @click="closeEdit">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify-end>
              <v-col>
                <span v-if="address.address_type.constant !== 'BILLING'">
                  Please make sure you have a billing address
                  filled out as we use the billing address as a primary address.
                </span>
                <span class="req-diclaimer float-right">* = Required</span>
              </v-col>
            </v-row>
            <v-select
              v-model="address.address_type"
              label="Address Type *"
              :items="address_types"
              item-text='name'
              item-value='id'
              color='primary'
              dense
              outlined
              autocomplete="new"
              return-object
              :rules="[required('Address Type', address.address_type.id)]"
              required>
            </v-select>
            <v-text-field
              label="Address *"
              v-model="address.address_1"
              :counter="50"
              :rules="[required('Street Address', address.address_1), addressRule]"
              color='primary'
              dense
              outlined
              autocomplete="new"
              required>
            </v-text-field>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Address 2 (optional)"
                  v-model="address.address_2"
                  :counter="25"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Apt, Suite, Bldg. (optional)"
                  v-model="address.address_3"
                  :counter="25"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="City *"
                  v-model="address.city"
                  :counter="25"
                  :rules="[required('City', address.city)]"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new"
                  required>
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Postal Code *"
                  v-model="address.postal_code"
                  :counter="10"
                  :rules="[required('Postal Code', address.postal_code)]"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new"
                  required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-combobox
                  label="State | Province *"
                  :items="state_provinces"
                  item-text="name"
                  item-value="id"
                  v-model="address.state_province"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new"
                  :rules="[required('State | Province', address.state_province.id)]"
                  required
                  auto-select-first>
                </v-combobox>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-combobox
                  label="Country *"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  v-model="address.country"
                  color="primary"
                  dense
                  outlined
                  autocomplete="new"
                  :rules="[required('Country', address.country.id)]"
                  required
                  auto-select-first>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col class="d-flex justify-end">
                <v-btn
                  id="editBtn"
                  v-if="editing === true"
                  color="success" class="mr-4"
                  :disabled="disabled(address, ['address_type.id', 'address_1', 'city', 'postal_code', 'state_province.id', 'country.id'])"
                  @click="putAddress">
                  Update
                </v-btn>
                <v-btn
                  id="createBtn"
                  v-if="creating === true"
                  color="success"
                  class="mr-4"
                  :disabled="disabled(address, ['address_type.id', 'address_1', 'city', 'postal_code', 'state_province.id', 'country.id'])"
                  @click="postAddress">
                  Create
                </v-btn>
                <v-btn
                  id="resetBtn"
                  color="warning"
                  class="mr-4"
                  @click="reset">
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>
<script>
import Address from '@/axios/party-address-endpoint'

import { cloneDeep } from 'lodash'

// mixins
import { displayAlert } from '@/mixins/alert'
import { fullwidth } from '@/mixins/fullwidth'
import { formValidation } from '@/mixins/formvalidation'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      search: '',
      pageSize: 5,
      pageSizes: [5, 10, 20],
      page: 1,
      info: null,
      valid: true,
      empty: false,
      creating: false,
      editing: false,
      crud: 'Create',
      select: null,
      selected: [],
      headers: [
        { text: 'Address Type', sortable: true, filterable: true, value: 'address_type.name', align: 'center' },
        { text: 'Address 1', sortable: true, filterable: true, value: 'address_1', align: 'center' },
        { text: 'Address 2', sortable: true, filterable: true, value: 'address_2', align: 'center' },
        { text: 'City', sortable: true, filterable: true, value: 'city', align: 'center' },
        { text: 'Postal Code', sortable: true, filterable: true, value: 'postal_code', align: 'center' },
        { text: 'State/Provinces', sortable: true, filterable: true, value: 'state_province.constant', align: 'center' },
        { text: 'Country', sortable: true, filterable: true, value: 'country.constant', align: 'center' },
        { text: 'Created By', sortable: true, filterable: true, value: 'created_by', align: 'center' },
        { text: 'Created On', sortable: true, filterable: true, value: 'created_on', align: 'center' },
        { text: 'Updated By', sortable: true, filterable: true, value: 'updated_by', align: 'center' },
        { text: 'Updated On', sortable: true, filterable: true, value: 'updated_on', align: 'center' }
      ],
      addressRule: v => (v && v.length <= 50) || 'Name must be less than 50 characters',
      nameRule: [
        (v) => !!v || 'Party Name is required',
        (v) => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      typeinfoRule: [
        (v) => !!v || 'Party Info is required'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      address: {},
      addressData: {
        id: '',
        party_id: '',
        address_type: { id: '', name: '' },
        address_1: '',
        address_2: '',
        address_3: '',
        city: '',
        postal_code: '',
        state_province: { id: '', name: '' },
        country: { id: '', name: '' },
        created_by: '',
        updated_by: '',
        created_on: '',
        updated_on: ''
      },
      addressList: []
    }
  },
  props: {
    value: String
  },
  mixins: [fullwidth, displayAlert, formValidation, userAccess],
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created () {
    this.nonTableHeight = 403
    this.getAddressList()
    this.address.party_id = this.value
  },
  computed: {
    address_types () {
      return this.$store.getters.address_types
    },
    countries () {
      return this.$store.getters.countries
    },
    state_provinces () {
      return this.$store.getters.state_provinces
    }
  },
  methods: {
    async postAddress () {
      this.valid = false
      this.validate()

      if (!this.valid) {
        return this.emitAlert(true, 'warning', 'Please check your form for errors')
      } 
      const addressData = {
        party_id: this.value,
        address_type_id: this.address.address_type.id,
        address_1: this.address.address_1,
        address_2: this.address.address_2,
        address_3: this.address.address_3,
        city: this.address.city,
        postal_code: this.address.postal_code,
        state_province_id: this.address.state_province.id,
        country_id: this.address.country.id,
        created_by: this.$store.getters.email
      }
      try {
        const res = await Address.post(addressData)
        if (res?.data?.id) {
          this.emitAlert(true, 'success', 'Your party\'s address has been successfully created!')
          this.reset()
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async putAddress () {
      this.valid = false
      this.validate()

      if (!this.valid) {
        return this.emitAlert(true, 'warning', 'Please check your form for errors')
      } 

      const addressClone = cloneDeep(this.address)
      const addressData = {
        party_id: this.value,
        address_type_id: addressClone.address_type.id,
        address_1: addressClone.address_1,
        address_2: addressClone.address_2,
        address_3: addressClone.address_3,
        city: addressClone.city,
        postal_code: addressClone.postal_code,
        state_province_id: addressClone.state_province.id,
        country_id: addressClone.country.id,
        updated_by: this.$store.getters.email
      }
      try {
        await Address.put(this.address.id, addressData)
        this.emitAlert(true, 'success', 'Your party\'s address has been successfully updated!')
        this.reset()
      } catch (err) {
        this.handleError(err)
      }
    },
    getAddressEdit (key) {
      Address.get(key.id)
        .then(response => {
          this.address = response.data
          this.empty = false
          this.editing = true
          this.creating = false
          this.crud = 'Update'
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getAddressList () {
      await Address.getList(this.value, 100, 0)
        .then(response => {
          this.addressList = response.data
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    selectRow (row) {
      this.crud = 'Update'
      if (row.value) {
        this.resetAlert()
        this.address = cloneDeep(row.item)
        this.editing = true
        this.creating = false
        this.showDialog = true
      } else {
        this.closeEdit()
      }
    },
    emitSelect (key, row) {
      if (row.isSelected === true) {
        row.isSelected = false
        row.select(false)
        this.closeEdit()
      } else {
        this.showDialog = true
        row.isSelected = true
        row.select(true)
      }
    },
    createNewAddress () {
      this.resetAlert()
      this.showDialog = true
      this.crud = 'Create'
      const base = cloneDeep(this.addressData)
      base.address_type = this.address_types.filter(type => type.constant === 'BILLING')[0]
      base.country = this.countries.filter(type => type.constant === 'USA')[0]
      this.address = base
      this.editing = false
      this.creating = true
    },
    closeEdit () {
      this.showDialog = false
      this.editing = false
      this.creating = false
      if (document.querySelector('.v-data-table__selected')) {
        document.querySelector('.v-data-table__selected').click()
      }
    },
    reset () {
      this.address.party_id = this.value

      this.getAddressList()

      this.closeEdit()
      this.crud = 'Create'
    },
    back () {
      this.$router.push({ name: 'Parties' })
    }
  }
}
</script>
